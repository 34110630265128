
.audio-container {
  max-width: 100%;
  box-sizing: border-box;
  padding-bottom: 0px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 160px;
  width: 900px;
}

.audio-container h1 {
  font-family: ariel, san-serif;
  text-shadow: 3px 3px 30px lightblue;
}

.music-marquee {
  height: 30px;
  background: #000;
  margin: 20px 0;
  font-size: 20px;
  color: lightblue;
  overflow: hidden;
  border-top: lightblue inset 1px;
  border-bottom: lightblue inset 1px;
}

@keyframes headingMarquee {
  0% {
    transform: translateX(300%);
  }
  100% {
    transform: translateX(-300%);
  }
}

.music-marquee .headings {
  text-align: left;
  display: inline-block;
  font-family: arial, sans-serif;
  text-transform: uppercase;
  animation-name: headingMarquee;
  animation-duration: 10s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.mp3list-buttons {
  margin-bottom: 20px;
  width: 100%;
}
.mp3list-buttons button {
  padding: 2px;
  color: #fff;
  border-radius: 5px;
  border: 1px solid #77ccff;
  margin: 0 2.3%;
  text-transform: uppercase;
  width: 20%;
  float: left;
  color: white;
  transition-property: background-color;
  transition-duration: 2s;
}

.mp3list-buttons button:hover {
  background-color: #cfc;
  color: rgb(117, 119, 0);
}

.clicked-mp3-button {
  background-color: #090;
  color: black;
}

.unclicked-mp3-button {
  background-color: rgb(47, 29, 97);
}

@media only screen and (max-width : 720px) {
  .changeFont{
    font-size: 5px!important;   
    width: 280px!important;
  }


  .move-left { 
    float: left;
    width: 350px;
    margin-right: 20px;
    /* width: 700px; */
}
button {
   height: 30px;
   width: 20%!important;
   margin: 0px 0px 0px 0px!important;
   
}




.audio-container {
  width: 290px;
  margin-right: 30px!important;
  
}
/* section.music-marquee {
  width: 100%;
} */

}

