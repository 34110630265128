@import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300&display=swap");

.frame {
  background-image: url ("./images/textureBackgroun.jpeg");
}

div.Polaris-TopBar {
  height: 200px;
  background-image: url("./images/textureBackgroun.jpeg");
}

/* Search Height Width */
div.Polaris-TopBar-SearchField {
  width: 180px;
  height: 50px;
}

/* Background Color Searchfield */
div.Polaris-TopBar-SearchField__Backdrop {
  background: rgb(255, 212, 132);
}
/* .header {
  margin-top: 10px;
  height: 100px;
  font-size: 25px;
  width: 100%;
  background-image: url("./images/textureBackgroun.jpeg");
} */

.navbar-wrapper {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 50px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  top: 0;
  right: 0;
  width: 100% !important;
  left: 0;
  z-index: 20;
  background-image: url("./images/textureBackgroun.jpeg");
}

/* navbar links */
a.nav-link {
  font-size: 25px;
  display: inline;
  /* margin-left: 20px !important; */
}

div.left {
  margin-left: 50%;
  position: absolute;
  margin-top: 0px;
}

.body {
  background: black;
}

div.card {
  font-size: 25px;
  margin-top: 150px;
  width: 600px;
  background-image: url("./images/emailFormBackground.jpeg");
  padding: 10px;
  border-radius: 0.3em;
  margin-bottom: 20px;
}

.form-group {
  height: 50px;
  text-align: center;
  background: transparent;
  margin-top: 0px;
}

#exampleFormControlInput1 {
  font-size: 15px;
}

label.lightgrey {
  color: lightgrey;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 30px;
}
div.imageDiv {
  margin-top: 150px;
  margin-left: 30px;
}

h1.fontFamily {
  font-family: "Open Sans Condensed", sans-serif;
}
h2.role {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 20px;
}

.listItems {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 20px;
}

.italic {
  font-style: italic;
}

.audio-container {
  margin-left: 15%;
}

.header {
  width: 100%;
  height: 100px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.alert {
  padding: 0.8rem;
  margin-top: 150px !important;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
  width: 40%;
  margin-bottom: 0px !important;
  margin-left: auto;
  margin-right: auto;
}
.alert-1 {
  margin-top: 150px !important;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
  margin-top: 150px;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

/* img.resize {
  max-width: 30%;
  max-height: 100%;
} */

@media screen and (max-width: 500px) {
  .header a {
    float: left;
    display: block;
    text-align: left;
    overflow: auto;
  }
  .header {
    float: left;
  }
}

/* DEVCONNECTOR CSS */

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

/* STOP */
/* Global Styles */
:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

/* Utilities */
.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Forms */
.form .form-group {
  margin-top: 0px;
}

.form .form-text {
  display: block;
  color: #888;
}

.form input[type="text"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

.form input[type="submit"],
button {
  font: inherit;
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}
.form .social-input i.fa-facebook {
  color: #3b5998;
}
.form .social-input i.fa-instagram {
  color: #3f729b;
}
.form .social-input i.fa-youtube {
  color: #c4302b;
}
.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}
