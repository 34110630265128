.title {
    font-size: 25px;
    color: rgba(211, 211, 211, 0.77);
    font-family: "helvetica thin";

}

.subtitle {
    font-size: 25px;
    color: rgba(211, 211, 211, 0.503);
}

